import React, { useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

import { useDownloadFileFromURL } from "features/entity/services";

import { useFetchFileById, useFetchMediaById } from "features/files/services";

import { getURLFileExtension } from "utils/StringUtils";
import PreviewRenderer from "./PreviewRenderer";

import "./PreviewEngine.scss";
import { Button } from "react-bootstrap";

function PreviewEngine({ fileName, fileSrcURL, fileEXT, backLink }) {
  const {
    isLoading: isDownloading,
    downloadFileFromURL,
    error,
    success,
  } = useDownloadFileFromURL({
    fileURL: fileSrcURL,
    fileName: fileName,
  });

  const { fileId } = useParams();
  const [searchParams] = useSearchParams();
  const fileType = searchParams.get("fileType");

  const { isLoading: isExpanding, fetchMedia } = useFetchMediaById({
    mediaId: fileId,
    fetchByDefault: false,
    onSuccess: (file) => {
      const { id, name } = file;
      const fileURL = file.url || "";
      const fileEXT = getURLFileExtension(fileURL);

      downloadFileFromURL(fileURL, `${name || id}.${fileEXT}`);
    },
  });

  const { isLoading: isSynching, fetchFile } = useFetchFileById({
    fileId: fileId,
    fetchByDefault: false,
    onSuccess: (file) => {
      const { id, name } = file;
      const fileURL = file.url || "";
      const fileEXT = getURLFileExtension(fileURL);

      downloadFileFromURL(fileURL, `${name || id}.${fileEXT}`);
    },
  });

  const downloadFile = () => {
    if (fileType === "file") fetchFile();
    else fetchMedia();
  };

  useEffect(() => {
    downloadFile();
  }, []);

  const isLoading = isDownloading || isExpanding || isSynching;

  return (
    <>
      {(success || error) && !isLoading && (
        <div>
          <Button
            variant="outline-primary"
            as={Link}
            to={`${backLink?.link}`}
            className="mt-3"
          >
            <span translate="no" className="material-symbols-outlined me-2">
              arrow_back
            </span>
            Back to Files & Photos
          </Button>
        </div>
      )}
      <div
        className="row w-100 h-100 align-items-center justify-content-center"
        style={{ minHeight: "70vh" }}
      >
        <div className="col-12 text-center">
          <PreviewRenderer
            fileEXT={fileEXT}
            isLoading={isLoading}
            fileName={fileName}
            downloadFile={downloadFile}
            error={error}
            success={success}
          />
        </div>
      </div>
    </>
  );
}

export default PreviewEngine;
