import { Button, Spinner } from "react-bootstrap";

import "./PreviewRenderer.scss";
import Loader from "components/ui/Loader";

const PreviewRenderer = ({
  fileEXT,
  fileName,
  isLoading,
  downloadFile,
  error,
  success,
}) => {
  return (
    <>
      <div>
        <span
          className={`pt-2 fiv-sqo fiv-size-xl fiv-icon-${fileEXT} mb-2`}
        ></span>
      </div>
      {((!error && !success) || isLoading) && (
        <>
          <Loader />
          <p>Downloading</p>
          <p>{fileName}</p>
        </>
      )}
      {!isLoading && !error && success && (
        <p className="text-success mt-5">
          <span translate="no" className="material-symbols-outlined md-36 me-2">
            check
          </span>
          Download Complete
        </p>
      )}
      {!isLoading && error && (
        <>
          <p className="mt-5">File download failed. Try again</p>
          <Button
            variant="primary"
            size="sm"
            onClick={downloadFile}
            disabled={isLoading}
          >
            {isLoading ? <Spinner animation="border" size="sm" /> : null}
            <span className="material-symbols-outlined md-16">
              download
            </span>{" "}
            Download
          </Button>
        </>
      )}
    </>
  );
};

export default PreviewRenderer;
