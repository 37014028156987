import { getMimeType } from "features/files/utils/mimeTypeUtil";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getURLFileExtension } from "utils/StringUtils";

const ImagePreviewModal = ({ file, description, image }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const { url, mimeType } = file;
  const fileEXT = getURLFileExtension(url);
  const mimetype = mimeType || getMimeType(fileEXT);

  if (mimetype?.includes("image") === false) return <></>;

  return (
    <>
      <Button
        variant="outline-primary"
        size="sm"
        className="border-dark border-opacity-25 me-2"
        onClick={handleShow}
      >
        <span className="md-16 material-symbols-outlined ">visibility</span>
      </Button>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Image Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {url && <img src={url} alt={"preview"} fluid className="w-100" />}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImagePreviewModal;
