import { truncate } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useContext } from "react";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import { getURLFileExtension } from "utils/StringUtils";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import FacilityFileUpdate from "../FacilityFileUpdate";
import FacilityFileDelete from "../FacilityFileDelete";
import FacilityFileDownload from "../FacilityFileDownload";
import { getInAppFileURL } from "features/files/utils/permanentFileLink";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { ImagePreviewModal } from "features/files";

const FacilityFileListItem = ({ error, isLoading, files, setFiles }) => {
  const organiation = useContext(OrganizationContext);

  const onFileUpdate = (updatedFile) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) => {
        if (updatedFile?.id === file?.id) return updatedFile;
        return file;
      })
    );
  };

  const onFileDelete = (file) => {
    setFiles((prevFiles) => prevFiles.filter(({ id }) => file?.id !== id));
  };

  if (isLoading)
    return (
      <tr>
        <td colSpan={8}>
          <Loader />
        </td>
      </tr>
    );

  if (error)
    return (
      <tr>
        <td colSpan={8}>
          <ErrorHandler error={error} />
        </td>
      </tr>
    );
  if (!files) return <></>;
  if (!files.length)
    return (
      <tr>
        <td colSpan={8}>
          <EmptyStateHandler
            className="mb-3 d-flex flex-row"
            title="No Files Found"
            description="Files will appear here once added"
          />
        </td>
      </tr>
    );

  return files.map((file) => {
    const { id, name, description, updatedAt } = file;
    const fileURL = file.url || "";
    const fileEXT = getURLFileExtension(fileURL);
    return (
      <tr key={id}>
        <td className="text-wrap">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip-2">
                {name || `${id}.${fileEXT}`}
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <div
                ref={ref}
                size="sm"
                className="text-start p-0"
                {...triggerHandler}
              >
                <div className="d-flex flex-row">
                  <div>
                    <span
                      className={`fiv-sqo fiv-size-md fiv-icon-${fileEXT} me-2`}
                    ></span>
                  </div>
                  <span className="text-wrap">
                    {truncate(name || `${id}.${fileEXT}`, {
                      length: 25,
                    })}
                  </span>
                </div>
              </div>
            )}
          </OverlayTrigger>
        </td>
        <td className="text-wrap">{description}</td>
        <td>Entity</td>
        <td>
          <small>
            {new Date(updatedAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </small>
        </td>
        <td className="text-nowrap text-end">
          <ImagePreviewModal file={file} />
          <FacilityFileDownload file={file} />
          <FacilityFileUpdate file={file} onFileUpdate={onFileUpdate} />
          <FacilityFileDelete file={file} onFileDelete={onFileDelete} />
        </td>
      </tr>
    );
  });
};

export default FacilityFileListItem;
