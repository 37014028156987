import { useEffect, useRef, useState } from "react";

import { useToast } from "hooks";

import { download } from "utils/DeApi";
import { downloadFileBlob } from "features/entity/utils/downloadUtils";

const useDownloadFileFromURL = ({ fileURL, fileName }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const toast = useToast();
  const subscribedPromises = useRef([]);

  const downloadFileFromURL = (overrideURL, overrideName) => {
    setSuccess(false);
    setIsLoading(true);
    setError(null);
    const fileDownloadPromise = download(overrideURL || fileURL);

    fileDownloadPromise.promise
      .then((resp) => {
        downloadFileBlob(resp, overrideName || fileName);
        toast.success("Success", "File has been downloaded");
        setSuccess(true);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          let errorMessage = error?.data?.message || "Failed to download file";

          if (error?.status === 403) {
            errorMessage =
              "The link to the file has expired or is invalid. Please refresh the page and try again.";
          }
          toast.error("Error", errorMessage);
          setError(error);
          setSuccess(false);
        }
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(fileDownloadPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);
  return { error, isLoading, success, downloadFileFromURL };
};

export default useDownloadFileFromURL;
