import DOMPurify from "dompurify";
import moment from "moment";

export function slugify(text) {
  text = text || "content";
  const a = "àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
  const b = "aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------";
  const p = new RegExp(a.split("").join("|"), "g");
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with ‘and’
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple — with single -
    .replace(/^-+/, ""); // Trim — from start of text .replace(/-+$/, '') // Trim — from end of text
}

export function getURLHostName(URL) {
  //Remove protocol (http, ftp, etc.) and get hostname
  let hostName = URL.indexOf("//") > -1 ? URL.split("/")[2] : URL.split("/")[0];

  //Remove portNumber
  hostName = hostName.split(":")[0];
  //Remove "?"
  hostName = hostName.split("?")[0];

  return hostName;
}

export function formatDateRange(date) {
  const [startDate, endDate] = date;
  if (!startDate || !endDate) return "";
  const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
  const formatedEndDate = moment(endDate).format("YYYY-MM-DD");
  return `${formattedStartDate},${formatedEndDate}`;
}

export function getURLDomain(URL) {
  let domain = getURLHostName(URL),
    splitArr = domain.split("."),
    arrLen = splitArr.length;

  //Check for subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
    //If using Country Code Top Level Domain (ccTLD) (i.e. ".co.ke")
    if (
      splitArr[arrLen - 2].length === 2 &&
      splitArr[arrLen - 1].length === 2
    ) {
      domain = splitArr[arrLen - 3] + "." + domain;
    }
  }
  return domain;
}

export function stripHTML(htmlString) {
  if (!htmlString) return "";
  let doc = new DOMParser().parseFromString(htmlString, "text/html");
  let textContent = doc.body.textContent || "";
  doc.body.querySelectorAll("p").forEach((paragraph, index) => {
    if (index < doc.body.querySelectorAll("p").length - 1) {
      textContent += paragraph.textContent + "\n\n";
    }
  });
  return textContent;
}

export function stripHTMLTags(html) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
}

export function isValidURL(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}

export const isNotUndefined = (value) => {
  if (typeof value === "undefined") return false;
  return true;
};

export function getURLFileExtension(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
}

export function sanitizeHTML(htmlString) {
  if (!htmlString) return "";
  return DOMPurify.sanitize(htmlString, {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
  });
}

export function isStringDate(str) {
  return moment(str, moment.ISO_8601, true).isValid();
}

export function localizeDate(date) {
  const userLocale = navigator.language || "en-US";
  return new Intl.DateTimeFormat(userLocale).format(new Date(date));
}

export function truncateText(text, maxLength = 10) {
  if (!text) return "";
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
}
